export default {
  titles: {
    encoding: 'Encoding',
    inbound: 'Inbound',
    user_info: 'User Info',
    settings: 'Settings',
    reset_daily_items: 'Reset daily items?',
    last_parcels: 'Last Parcels',
    select_item: 'Select Item',
    select_product: 'Select Product',
    select_workstation: 'Select workstation',
    select_language: 'Select language',
    transfer_to_zone: 'Transfer to zone',
    support: 'Support',
    select_printer: 'Select printer',
    shipment_to_confirm: 'Shipment to Confirm',
  },
  fields: {
    current_location: 'Current Location',
    identified_item: 'Identified Item',
    ignored_items: 'Ignored Items',
    error_items: 'Error Items',
    warning_items: 'Warning Items',
  },
  misc: {
    is_vas: 'ATTENTION VAS',
    admin: 'Admin',
    save: 'Save',
    confirm: 'Confirm',
    cancel: 'Cancel',
    close: 'Close',
    user: 'User',
    workstation: 'Workstation',
    username: 'Username',
    name: 'Name',
    password: 'Password',
    login: 'Login',
    login_tmr: 'Login TMR',
    sso_login: 'SSO Login',
    encoding: 'Encoding',
    ean_code: 'EAN Code',
    certilogo: 'Certilogo',
    production_order: 'Serial number',
    enter_the_necessary_fields: 'Enter the necessary fields to start the operation',
    associated_items: 'Associated Items',
    associate: 'Associate',
    verify: 'Verify',
    reading: 'Reading',
    association: 'Association',
    waiting: 'Waiting',
    writing: 'Writing',
    retry: 'Retry',
    writing_association: 'Writing and association',
    confirmed: 'Confirmed',
    unable_to_write: `Unable to write epc tag : {{code}} for product: {{productCode}}`,
    unable_to_get_next_epc: `Unable to get next epc for: {{productCode}}`,
    unable_to_connect_to_workstation: 'Unable to connect to workstation',
    tag_info: 'Tag Info',
    tag_info_cq: 'Tag Info CQ',
    serie_info_cq: 'Info Serie',
    quality_control: 'Quality Control',
    inbound: 'Inbound',
    outbound: 'Outbound',
    stop: 'Stop',
    start: 'Start',
    pause: 'Pause',
    clear: 'Clear',
    tag_encoded_today: 'Tag Encoded today',
    ready_to_receive: 'Ready to receive',
    change_password: 'Change Password',
    logout: 'Logout',
    are_you_sure_to_logout: 'You really want to log out?',
    settings: 'Settings',
    roles: 'Roles',
    email: 'Email',
    place: 'Place',
    items: 'Items',
    description: 'Description',
    color: 'Color',
    size: 'Size',
    total_reads: 'TOTAL READS',
    save_to_list: 'Save to list',
    destination: 'Destination',
    origin: 'Origin',
    new_parcel: 'New Parcel',
    status: 'Status',
    parcel: 'Parcel',
    parcels: 'Parcels',
    shipment: 'Shipment',
    creation_date: 'Creation Date',
    search: 'Search',
    detail: 'Detail',
    quantity: 'Quantity ({{quantity}})',
    style: 'Style',
    material: 'Material',
    shipment_code_auto: 'Shipment code (auto)',
    parcel_code_auto: 'Parcel code (auto)',
    next: 'Next',
    parcels_to_receive: 'Parcels to receive',
    confirm_parcel: 'Confirm Parcel',
    image_missing: 'IMAGE MISSING',
    loading: 'Loading...',
    unknown_product: 'Unknown Product',
    unknown_tag: 'Unknown Tag',
    invalid_tag: 'Tag Not Associated',
    stock: 'Stock',
    valid: 'Valid',
    unknown: 'Unknown',
    actions: 'Actions',
    reprint: 'Reprint',
    product: 'Product',
    report_repair: 'Report Repair',
    reason: 'Reason',
    request_code_change: 'Request Code Change',
    enable_workstation_emulation: 'Enable Workstation emulation',
    filter: 'Filter',
    language: 'Language',
    warning: 'Warning',
    success: 'Success',
    info: 'Info',
    checklist: 'Checklist',
    optional: 'Optional',
    phone: 'Phone',
    printer: 'Printer',
    new_shipment: 'New Shipment',
    upc: 'UPC',
    model_part_color_size: 'Model / Part / Color / Size',
    confirm_shipment: 'Confirm Shipment',
    receive: 'Receive',
    delete_association: 'Delete Association',
    open: 'Open',
    variant: 'Variant',
    variant_description: 'Variant description',
    sku: 'Sku',
    fit: 'Fit',
    product_header: 'Model / Aeshetic Variant / Descr. Model / Description A.V. / Sku / Size / Fit',
    serie: 'Serie',
    total_quantity_serie: 'Total Serie Quantity',
    total_quantity_lots: 'Total Lots Quantity',
    shipable_quantity: 'Shipable Quantity',
  },
  error: {
    serie_already_added: 'Serie already added',
    serie_not_found: 'Serie not found',
    error: 'Error',
    forbidden: 'Forbidden',
    not_found: 'Not Found',
    unauthorized: 'Unauthorized',
    too_many_tags_read: 'Too many tags read (expected {{exptected}} read {{read}})',
    too_many_checklist_types: 'Too many checklist types. Make sure to select parcels with same checklist type.',
    rfid_discrepancies_found: 'RFID Discrepancies found, clear all and try again or confirm with discrepancies',
    certilogo_not_found: 'Certilogo "{{code}}" not found',
    ean_not_found: 'EAN "{{code}}" not found',
    production_order_not_found: 'Serial number "{{code}}" not found',
    no_inbound_zones: 'No Inbound zones',
    no_operation_config: 'Operation configuration not found',
    not_supported_reading_mode: 'Reading mode not supported',
    undefined_operation_config: 'Operation configuration cannot be undefined',
    certilogo_already_encoded: 'Certilogo "{{code}}" already encoded',
    tag_not_found: 'Tag not found',
    tag_unassociated: 'Tag unassociated',
    tag_type_unexpected: 'Unexpected tag type',
    tag_already_associated: 'Tag already associated',
    tags_not_associated: 'One or more tags are not associated',
    tags_mismatch: 'Tags mismatch',
    workstation_could_not_be_started: 'Workstation could not be started',
    authentication_failed: 'Authentication failed',
    destination_field_required: 'Destination field required',
    unable_to_connect_to_rfid_reader: 'Unable to connect to RFID Reader. Check that the device is turned On.',
    items_in_error_found: 'Items in error found',
    item_not_in_inbound: 'Item not in inbound',
    item_not_in_stock: 'Item not in stock',
    item_in_another_parcel: 'Item in another parcel',
    item_in_another_production_order: 'Item in another production order',
    field_is_mandatory: '{{field}} is mandatory.',
    field_must_be_number: '{{field}} must be a number.',
    serie_status_change: 'Error in the status change of the series in ',
    no_discard_causal_selected: 'Reason for rejection not selected',
    no_product_selected: 'No product selected',
    not_associated_product: 'Product not belonging to the series or wrong size / fit',
    not_collaudated_product: 'Prodotto non battezzato',
    status_not_admitted: 'Stato non ammesso',
    serie_code_10_characters: 'Codice serie diverso da 10 caratteri',
    serie_code_14_characters: 'Codice prodotto diverso da 14 caratteri',
    serie_wrong_place: "Serie non appartenente al place dell'utente",
    serie_inspector_check: "La serie deve essere controllata dall'ispettore",
    product_already_associated: 'Questo prodotto risulta già associato alla serie',
    product_already_dropped: 'Questo prodotto risulta scartato',
    tag_reset_error: "Errore nell'operazione di reset del tag",
    product_drop_error: 'Errore nello scarto del prodotto',
    reader_connection_error: 'Impossibile connettersi al lettore',
    product_irreversible_dropped: 'Questo prodotto è in stato scartato in modalità irreversibile',
    product_already_associated_by_inspector: "Questo prodotto risulta già collaudato dall'ispettore",
    serie_error: 'Errore con la serie',
    product_not_selected: 'Prodotto non selezionato',
    serie_limit_associate_error: 'Limite pezzi collaudati raggiunto',
    writing_tag_error: "Errore nell'operazione di scrittura del tag",
    product_association_error: "Errore nell'associazione del prodotto",
    larger_file_error: 'Maximum upload size is 5MB',
    user_disabled: 'The account is disabled, please contact the administrator',
    FERRAGAMO_ERROR_BARCODE_NOT_FOUND: 'Serie non presente su FAST',
    FERRAGAMO_ERROR_FAST_CONNECTION: 'Errore di connessione a FAST',
    FERRAGAMO_ERROR_SERIES_COMPETENCE_ERROR: 'Non è una serie di produzione',
    FERRAGAMO_ERROR_SERIES_STATUS_ERROR: 'La serie non è in uno stato corretto',
    FERRAGAMO_ERROR_SERIES_STATUS_ERROR_READY_INSPECTION_2: 'La serie è in stato "da ispezionare"',
    FERRAGAMO_ERROR_SERIES_STATUS_ERROR_INSPECTED_2: 'La serie è in stato "in ispezione"',
    FERRAGAMO_ERROR_SERIES_STATUS_ERROR_INSPECTED_1: 'La serie è in carico al lavorante',
    FERRAGAMO_ERROR_SERIES_STATUS_ERROR_READY_INSPECTION_1: 'La serie è in stato "da lavorare"',
    FERRAGAMO_ERROR_SERIES_STATUS_ERROR_READY_SUSPENDED: 'La serie è in stato "sospeso"',
    FERRAGAMO_ERROR_SERIES_TAG_NEEDED_ERROR: 'Serie con qualityCheckTag diverso da Y su Fast',
    FERRAGAMO_ERROR_TAG_NOTFOUND_ERROR: 'Tag non trovato su Fast',
    FERRAGAMO_ERROR_TAG_STATUS_ERROR_ALREADY_TESTED: 'Pezzo già collaudato',
    FERRAGAMO_ERROR_TAG_STATUS_ERROR_ALREADY_ENCODED: 'Pezzo già battezzato',
    FERRAGAMO_ERROR_TAG_STATUS_ERROR_TESTED_BY_INSPECTOR: 'Pezzo già collaudato dall’ispettore',
    FERRAGAMO_ERROR_SERIES_MISMATCH_ERROR: 'Pezzo non della taglia/calzata letta o pezzo non della serie letta',
    FERRAGAMO_CQ_ENCODING_ERROR_ORDER_ROW_NOT_FOUND: "Riga d'ordine non trovata",
    FERRAGAMO_CQ_ENCODING_ERROR_ORDER_ROW_PRODUCT_MISMATCH: 'Il pezzo che si sta tentando di battezzare è stato già assegnato a modello/taglia-calzata differente. Se necessario cancellare.',
    FERRAGAMO_CQ_ENCODING_ERROR_ORDER_ROW_ALL_ITEMS_ALREADY_ENCODED: 'Tutti i prodotti sono già stati collaudati per il modello selezionato',
    ENCODING_ERROR_PRODUCT_NOT_IN_PRODUCTION_ORDER: "Il prodotto non appartiene all'ordine inserito",
    FERRAGAMO_ERROR_TAG_STATUS_ERROR_ENCODED: 'Pezzo battezzato: il pezzo passa da stato buono a stato scarto',
    WEBRESOURCE_GENERIC_ERROR: 'Generic error',
    product_already_read: 'Product already read',
    FERRAGAMO_ERROR_SERIES_STATUS_ERROR_CLOSED: 'Serie già collaudata',
    FERRAGAMO_CQ_RESET_ERROR_ITEM_NOT_IN_OPEN_LOT: 'Pezzo non appartenente ad un lotto aperto.',
    FERRAGAMO_CQ_RESET_ERROR_ITEM_ITEM_IN_CLOSED_LOT: 'Pezzo appartenente ad un lotto chiuso.',
    FERRAGAMO_CQ_TESTING_NO_SELECTED_LOTS_BOUND_TO_ITEM: 'Pezzo appartenente ad un lotto che non è stato preso in carico.',
    FERRAGAMO_ERROR_TAG_STATUS_ERROR_NOT_REVERSIBLE_DISCARDED: 'Pezzo scartato in maniera non reversibile',
  },
  messages: {
    product_associated_correctly: 'Prodotto battezzato correttamente',
    product_dropped_correctly: 'Prodotto scartato',
    product_cancel_correctly: 'Associazione cancellata',
    product_already_dropped_info: 'Questo prodotto risulta già scartato con causale',
    product_association_proceed: 'Procedere con il collaudo?',
    serie_confirm_interrupt: 'Conferma interruzione',
    serie_interrupt_association_question: 'Sei sicuro di voler interrompere il collaudo?',
    serie_closed: 'Serie chiusa correttamente',
    serie_interrupted_correctly: 'Serie interrotta correttamente',
    inspected_1: 'Lavorante',
    ready_inspection_2: 'Da ispezionare',
    inspected_2: 'Ispettore',
    suspended: 'Sospesa',
    closed: 'Chiusa',
    correct: 'Correttamente',
    drop_proceed: 'Procedere con lo scarto?',
    serie_change_confirm: 'Conferma cambio serie',
    serie_close_product_not_associated: "Il prodotto appartiene ad un'altra serie, sicuro di voler chiudere la serie corrente?",
    near_product_to_reader: 'Avvicina il prodotto al lettore',
    no_workstations_found: 'No Workstations Found',
    no_workstation_selected: 'No Workstation selected',
    no_place_selected: 'No Place selected',
    no_seat_selected: 'Insert a seat ID',
    are_you_sure_to_reset_daily_items: 'Are you sure you want to reset the daily items?',
    bring_an_item_to_antenna: 'Bring an item close to the antenna',
    press_start_to_read: 'Press Start button to \nstart reading RFID products',
    paste_comma_separeted_tags: 'Paste comma separated tags then press return...',
    connection_workstation_progress: 'Connection to workstation in progress..',
    operation_success: 'Operation successfully confirmed',
    outbound_success: 'Outbound successfully confirmed',
    inbound_success: 'Inbound successfully confirmed',
    transfer_to_zone_success: 'Transfer To Zone successfully confirmed',
    list_empty: 'List is empty',
    looks_like_anything_in_list: "Looks like you don't have anything in your list",
  },
  placeholder: {
    total_pieces: 'Totale pezzi',
    associated: 'Battezzati',
    to_be_tested: 'Da Collaudare',
    drop_causal: 'Causale scarto',
    tested: 'Collaudati',
    dropped: 'Scartati',
    remain: 'Rimanenti',
    confirm_serie: 'Conferma serie',
    suspend_serie: 'Sospendi serie',
    interrupt_association: 'Interrompi Collaudo',
    start_reader: 'Avvia lettore',
    continue: 'Continua',
    attention: 'Attenzione',
    search_shipment_parcel: 'Search Shipment or Parcel',
    search_product_parcel: 'Search Product or Parcel',
  },
  confirm: {
    discrepancy_title: 'Parcel with discrepancies',
    discrepancy_message: 'Products with discrepancies were found. Are you sure you want to confirm the parcel?',
    confirm_delete_association: 'Are you sure you want to delete this item association?',
    serie_found_discrepancy: 'Discrepancies found. Are you sure you want to confirm the series?',
    discarded_pieces_title: 'Discarded pieces confirm',
    discarded_pieces_close_message: 'There are some discarded pieces within the series, do you want to continue with the closure?',
    discrepancy_total_encoded_pieces: 'Attention: series with discrepancies between the total and encoded pieces; pay maximum attention and control even to the unencoded pieces',
  },
  labels: {
    shippable: 'Shippable',
    serie: 'Serie',
    draft: 'Draft',
    ready_to_send: 'Ready to send',
    send_await: 'Pending',
    outbound_error: 'Outbound error',
    sent: 'Sent',
    in_inbound: 'In Inbound',
    receive_await: 'Pending',
    ready_to_receive: 'Readty to receive',
    inbound_error: 'Inbound error',
    received: 'Received',
    in_transit: 'In transit',
  },
}
