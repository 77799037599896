import React, { Component } from 'react'
import {
  AntennaBottomControl,
  Box,
  Button,
  Card,
  CustomProductRow,
  InfoCard,
  Input,
  Page,
  Placeholder,
  Select,
  SelectItemModal,
  Spacer,
  Tab,
  TextBox,
  Icons,
  KeyValueRow,
  InfoModal,
} from 'components'
import AppStore from 'AppStore'
import { __, T } from 'translations/i18n'
import styled from '@emotion/styled'
import { askUserConfirmation, closeModal, openModal, showToast, sleep } from 'shared/utils'
import { CQTag, CQCounters, CQSerie, Causal, QualitySheet, ProductionOrderRow, CQLot, TestingSheet } from 'api/types'
import RemoteConfig from 'shared/RemoteConfig'
import EncodingsQC from 'api/EncodingsQC'
import Sounds from 'shared/Sounds'
import SeriesNotesModal from 'components/modals/SeriesNotesModal'
import SelectLotsModal from 'components/modals/SelectLotsModal'
import DataDogLogger from 'shared/DataDogLogger'
import QualitySheetModal from 'components/modals/QualitySheetModal'
import QualityControlAgent from './QualityControlAgentNew'

interface State {
  showQualitySheetModal: boolean
  options: { value: string; label: string; active: boolean }[]
  isReading: boolean
  isVerifing: boolean
  isWriting: boolean
  errorSerie: any
  serie?: CQSerie
  counters?: CQCounters
  item?: any
  tag?: CQTag
  warningText?: string
  isSerieSet: boolean
  isActionInProgress: boolean
  causal: Causal[]
  selectedCausal?: Causal
  qualitySheetData?: QualitySheet
  lastSearchedSerieCode?: string
  showScartoModal: boolean
  hideStartReaderButton: boolean
  lots?: CQLot[]
  showSeriesNotesModal: boolean
  testingSheetData?: TestingSheet
  testedPieces?: number
  inspectorTested?: number
  discarded?: number
  toBeTested?: number
  isVAS: boolean
  hasUnexpectedTag?: boolean
  lastReadedTag?: string
}

export default class QualityControlWorker extends Component<{}, State> {
  state: State = {
    showQualitySheetModal: false,
    options: [
      { value: 'COLLAUDO', label: 'collaudo', active: true },
      { value: 'SCARTO', label: 'scarto', active: false },
      { value: 'CANCELLAZIONE', label: 'cancellazione', active: false },
    ],
    isReading: false,
    isVerifing: false,
    isWriting: false,
    causal: [],
    errorSerie: '',
    isSerieSet: false,
    isActionInProgress: false,
    showScartoModal: false,
    hideStartReaderButton: false,
    showSeriesNotesModal: false,
    isVAS: false,
    hasUnexpectedTag: false,
    lastReadedTag: undefined,
  }
  clearInputRef: any = undefined
  inputRef = React.createRef<HTMLInputElement>()
  operation = RemoteConfig.getOperationConfig<any>('quality-control')
  isPelletteria = (AppStore.defaultPlace?.attributes as any)?.categoryType === 'P'
  async componentDidMount() {
    try {
      await this.stopReader()
      const causal = await EncodingsQC.fetchCausal(this.isPelletteria ? 'P' : 'C')
      this.setState({ causal })
    } catch (error) {
      DataDogLogger.addError(error, { type: 'fetchCausal' })
      showToast({
        sound: false,
        title: __(T.error.error),
        description: (error as Error).message,
        status: 'error',
      })
    }
  }

  async componentWillUnmount() {
    await this.stopReader()
  }

  clearStatus = async () => {
    await this.stopReader()
    this.clearInputRef()
    await this.setState({
      showQualitySheetModal: false,
      options: [
        { value: 'COLLAUDO', label: 'collaudo', active: true },
        { value: 'SCARTO', label: 'scarto', active: false },
        { value: 'CANCELLAZIONE', label: 'cancellazione', active: false },
      ],
      isReading: false,
      isVerifing: false,
      isWriting: false,
      errorSerie: '',
      isSerieSet: false,
      isActionInProgress: false,
      qualitySheetData: undefined,
      serie: undefined,
      counters: undefined,
      item: undefined,
      tag: undefined,
      warningText: undefined,
      showScartoModal: false,
      lots: undefined,
      testingSheetData: undefined,
      isVAS: false,
      hasUnexpectedTag: false,
    })
  }

  toggleQualitySheetDetail = () => {
    const { showQualitySheetModal, serie } = this.state
    DataDogLogger.addAction('toggleQualitySheetDetail', {
      serie: serie ?? 'serie is undefined',
      show: !showQualitySheetModal,
    })
    this.setState({ showQualitySheetModal: !showQualitySheetModal })
  }

  changeOptions = async (selectedOption: string) => {
    const { options } = this.state
    options.map((option) => {
      option.active = option.value === selectedOption
      return option
    })
    this.setState({
      options,
      selectedCausal: undefined,
    })
  }

  getUpdatedCounters = async (code?: string) => {
    const { serie } = this.state
    const serieCode = code ?? serie!.code
    const counters: CQCounters = await EncodingsQC.getEncodable(serieCode)
    if (!counters) throw Error('Impossibile definire contatori serie')
    return counters
  }

  //funzione chiamata quando si cerca una serie
  searchSerie = async (code: string) => {
    await this.getUpdatedSerie(code)
  }

  getUpdatedSerie = async (code: string, startReader = true) => {
    try {
      await this.stopReader()
      code = code.toUpperCase()
      const { serie } = this.state
      const { options } = this.state
      let warningText
      if (this.isPelletteria && code.length !== 10) throw new Error(__(T.error.serie_code_10_characters))
      if (!this.isPelletteria && code.length !== 14) throw new Error(__(T.error.serie_code_14_characters))

      const newSerie = await EncodingsQC.getCQCustomProductionOrder(code, this.operation)

      if (!newSerie) throw new Error(__(T.error.serie_not_found))
      if (newSerie.worker.code !== AppStore.defaultPlace?.code) throw new Error(__(T.error.serie_wrong_place))

      if (serie && newSerie?.code !== serie?.code) {
        if (
          !(await askUserConfirmation(
            __(T.messages.serie_change_confirm),
            __(T.messages.serie_close_product_not_associated)
          ))
        )
          return false
        await this.closeLotto()
      }

      let row
      newSerie.rows.sort((a, b) => a.product.size.value - b.product.size.value)
      if (this.isPelletteria) row = newSerie.rows[0]
      else row = newSerie.rows.find((r) => r.rowCode === code) as any

      if (!row?.product) throw Error('Taglia/calzata non trovata')
      DataDogLogger.addAction('searchSerie', { serie: newSerie })
      if (newSerie.availableQuantity === 0)
        showToast({
          title: 'Attenzione',
          description: "Esistono pezzi battezzati sulla serie. Usa l'operazione di cancellazione per rimuoverli",
          status: 'warning',
        })
      let hideStartReaderButton = false
      let hasUnexpectedTag = false
      if (newSerie && newSerie.destination && newSerie.destination.activeTag !== '') {
        warningText = newSerie.destination.fastMess
        if (newSerie.destination!.activeTag === 'N') hideStartReaderButton = true
        if (warningText === 'NON DEVE CONTENERE IL TAG') hasUnexpectedTag = true
      }
      let isVAS = false
      if (newSerie && newSerie.customerOrder && newSerie.customerOrder === '24S1') isVAS = true
      const qualitySheetData = (
        await EncodingsQC.getQualitySheet(
          newSerie.qualityCode || '',
          newSerie?.worker.code,
          row?.product?.style?.value ?? ''
        )
      )[0] // 'Q_CODE_TEST', 'studio-pelle'
      const testingSheetData = (await EncodingsQC.getTestingSheet(newSerie.id))[0]
      const counters = await this.getUpdatedCounters(newSerie.code)
      if (!counters) throw new Error(__('Errore nel recupero dei counters'))
      const lotCounts = counters!.itemStatusCounts.lotCounts
      let filteredLots: CQLot[]
      if (serie && newSerie?.code === serie?.code) {
        filteredLots = newSerie.lots!.filter(
          (lot) => serie.data.currentLotData!.map((currentLot) => currentLot.id).indexOf(lot.id) !== -1
        )
        //serie.data.currentLotData!
      } else {
        filteredLots = newSerie.lots!.filter(
          (lot) => lot.status === 'INSPECTED_2' || lot.status === 'READY_INSPECTION_2'
        )
        if (filteredLots.length === 0) throw new Error(__('Nessun lotto da ispezionare'))
        if (filteredLots.length > 1) filteredLots = await this.showSelectLotsModal(filteredLots, lotCounts)
        await EncodingsQC.changeStatusLottiToInspected({
          orderId: newSerie!.code,
          lotIds: filteredLots!.map(({ id }) => id),
        })
      }
      const filteredLotsToInterrupt = filteredLots!.filter((lot) => lot.items.length === 0)
      console.log(filteredLotsToInterrupt)
      if (filteredLotsToInterrupt.length > 0) {
        await this.showInterruptModal(filteredLotsToInterrupt, newSerie.id)
        filteredLots = filteredLots!.filter((lot) => lot.items.length !== 0)
        if (filteredLots.length === 0) {
          throw new Error(__('Nessun lotto da ispezionare'))
        }
      }
      const selectedLotsCounters = lotCounts.filter(
        ({ lotId }) => filteredLots!.map(({ id }) => id).indexOf(lotId) !== -1
      )
      let testedPieces = 0
      let inspectorTested = 0
      let discarded = 0
      let toBeTested = 0
      let total = 0
      for (let i = 0; i < selectedLotsCounters.length; i++) {
        testedPieces += selectedLotsCounters[i].tested!
        inspectorTested += selectedLotsCounters[i].inspectorTested
        discarded += selectedLotsCounters[i].discardedNR!
        toBeTested += selectedLotsCounters[i].toBeTested!
        total += selectedLotsCounters[i].total!
      }
      const newSerieFormatted: CQSerie = {
        id: newSerie.id,
        code: newSerie.code,
        productId: row.product.id,
        data: {
          values: {
            'Tag/Cal': `${row.product.size.value}/${row.product.fit.value}`,
            Modello: row.product.style.value,
            Variante: row.product.variant.value,
            Stagione: row.product.season.slice(0, 2) + row.product.season.slice(4),
            Lavorante: newSerie.worker.description ?? 'aa',
            'Pezzi totali serie': newSerie.availableQuantity,
          },
          additional: {
            workerSyncCode: newSerie.worker.syncCode,
            productSku: row.product.sku,
            productSize: row.product.size.value,
            productCode: row.product.code ?? '',
            customerOrder: newSerie.customerOrder?.trim() ?? '',
            nationCode: newSerie.worker.countryCode ?? '',
          },
          descriptions: {
            Modello: row.product.style.valueDescription,
            Taglia: row.product.size.valueDescription,
            Calzata: row.product.fit.valueDescription,
            Variante: row.product.variant.valueDescription,
          },
          currentLotData: filteredLots,
        },
      }

      const lots = newSerie.lots!.filter((lot) => lot.status === 'INSPECTED_2' || lot.status === 'READY_INSPECTION_2')
      this.clearInputRef()
      this.setState({
        serie: newSerieFormatted,
        errorSerie: undefined,
        warningText,
        isSerieSet: true,
        counters,
        qualitySheetData,
        lastSearchedSerieCode: code,
        hideStartReaderButton,
        lots,
        testingSheetData,
        testedPieces,
        inspectorTested,
        discarded,
        toBeTested,
        isVAS,
        hasUnexpectedTag,
      })
      if (inspectorTested + discarded === total && serie) return await this.closeLotto(filteredLots)
      Sounds.tap()
      const mode = options?.find((opt) => opt?.active === true)?.value
      //in scarto non faccio partire il reader automaticamente perchè va prima selezionata la causale di scarto
      if (mode === 'COLLAUDO' && newSerie && startReader) {
        this.startReader()
      }
      return true
    } catch (error) {
      DataDogLogger.addError(error, { type: 'searchSerie' })
      showToast({
        title: __(T.error.error),
        description: (error as Error).message ?? __(T.error.serie_error),
        status: 'error',
      })
      this.clearInputRef()
      this.clearStatus()
      return false
    }
  }

  //show a list of produtcs and return the product on click
  showSelectProductModal = async (rows: ProductionOrderRow[]) => {
    rows.sort((a, b) => a.product.size.value - b.product.size.value)
    const modalId = 'cq-products'
    return new Promise((resolve, reject) => {
      openModal({
        id: modalId,
        body: (
          <SelectItemModal
            title={__(T.titles.select_product)}
            visible
            searchable
            options={rows ?? []}
            onSelect={(item) => resolve(item[0])}
            field="product.size.value"
            onClose={() => {
              closeModal(modalId)
              reject(__(T.error.product_not_selected))
            }}
            customRowRender={(row: ProductionOrderRow, selected: boolean) => {
              const { product } = row
              product.encoded = row.encoded
              product.quantity = row.quantity
              return <CustomProductRow product={product} selected={selected} />
            }}
          />
        ),
      })
    })
  }

  showInterruptModal = async (rows: CQLot[], code: string) => {
    const modalId = 'cq-lots-interrupt'
    const rowCodes = rows.map((row) => row.code).join(', ')
    const message =
      rows.length > 1
        ? `I lotti ${rowCodes} non contengono pezzi collaudabili e verranno interrotti`
        : `Il lotto ${rowCodes} non contiene pezzi collaudabili e verrà interrotto`
    return new Promise<any>((resolve) => {
      openModal({
        id: modalId,
        body: (
          <InfoModal
            title="conferma interruzione lotto"
            subtitle={message}
            onClose={() => {
              this.interruptLotto(rows, code)
              resolve(0)
              closeModal(modalId)
              return 0
            }}
          />
        ),
      })
    })
  }

  //Mostra modale per selezione lotti
  showSelectLotsModal = async (rows: any[], lotCounts: any[]) => {
    const modalId = 'cq-lots'
    const selectedLots: any[] = []
    rows = rows.sort((a, b) => (a.code > b.code ? 1 : -1))
    return new Promise<any>((resolve, reject) => {
      openModal({
        id: modalId,
        body: (
          <SelectItemModal
            title="Seleziona lotto/i"
            visible
            multiple
            forceSelect
            customBtnText="Conferma"
            selected={selectedLots}
            options={rows ?? []}
            onSelect={(items) => {
              resolve(items)
              closeModal(modalId)
            }}
            field="code"
            label="status"
            onClose={() => {
              return 0
            }}
            customRowRender={(row: CQLot, selected: boolean) => {
              const date = new Date(row.creationDate).toLocaleDateString()
              const time = new Date(row.creationDate).toLocaleTimeString()
              const dateTime = `${date} ${time}`
              const counters = lotCounts.filter((c) => c.lotId === row.id)[0]
              const pieces = counters.tested
              return (
                <KeyValueRow
                  variant="grey"
                  style={{ flex: 1, minHeight: 70 }}
                  valueStyle={{ whiteSpace: 'unset', color: row.plannedForTesting ? 'green' : ' red' }}
                  label={`Data inizio: ${dateTime}`}
                  value={`${row.code} - ${pieces} pz.`}
                  borderColor={selected ? '#222222' : undefined}
                  image={selected ? <Icons.CheckFilled /> : undefined}
                />
              )
            }}
            customFooter={() => {
              return (
                <Legend>
                  <LegendRow>
                    <LegendIcon color="green" />
                    <LegendText>Lotto pianificato</LegendText>
                  </LegendRow>
                  <LegendRow>
                    <LegendIcon color="red" />
                    <LegendText>Lotto non pianificato</LegendText>
                  </LegendRow>
                </Legend>
              )
            }}
          />
        ),
      })
    })
  }

  isCollaudoMode = () =>
    this.state.options.find((opt) => opt.active === true)?.value === 'COLLAUDO' && this.isPelletteria

  startReader = async () => {
    try {
      this.setState({
        isReading: true,
      })
      if (!AppStore.emulation) {
        await QualityControlAgent.testConnection()
        const { options, selectedCausal } = this.state
        const mode = options.find((opt) => opt.active === true)?.value
        if (mode === 'SCARTO' && !selectedCausal) throw Error('Imposta una causale prima di procedere')
        const tag = await QualityControlAgent.readTag('fake', 'fake')
        if (tag.status === '4') return this.startReader() //non è stato letto niente, riparte la lettura
        if (tag.status === '99') return //Operazione interrotta che non mostra popup di error
        if (tag.status === '0' && tag.uid === '') throw Error('Errore di lettura') //Lettura andata a buon fine ma senza il tag
        if (tag.status !== '0') throw Error(tag.errorDesc) // Gestione di tutti gli altri tipi di errore
        this.setState({ tag })
        this.verifyReadedTag(tag)
      }
    } catch (error) {
      this.setState({ isReading: false })
      showToast({
        title: __(T.error.error),
        description: (error as Error).message ?? 'Generic error',
        status: 'error',
      })
    }
  }

  verifyReadedTag = async (tag: CQTag) => {
    if (this.state.lastReadedTag && this.state.lastReadedTag === tag.uid) return this.startReader()
    try {
      this.setState({
        isReading: false,
        isVerifing: true,
      })
      const { options, serie } = this.state
      const mode = options?.find((opt) => opt?.active === true)?.value
      const res = await EncodingsQC.verifyCQInspector({
        productionOrderId: serie!.id,
        productId: serie!.productId,
        identifiers: [{ code: tag?.uid, type: 'NFCTag' }] as any,
        configurationId: this.operation.id,
        operationType: mode,
        testingLotIds: serie?.data.currentLotData?.map(({ id }) => id),
      })
      if (!res.item) throw Error(__(T.error.not_collaudated_product))
      if (res.errors.length > 0) {
        for (let i = 1; i <= res.errors.length; i++) {
          if (res.errors[i] && res.errors[i].errorCode) {
            //If there is more than one error, show them all in modals, than throw the first of them as effective error
            showToast({
              title: __(T.error.error),
              description: T.error[res.errors[i].errorCode.replaceAll('.', '_')]
                ? __(T.error[res.errors[i].errorCode.replaceAll('.', '_')])
                : res.errors[i].errorCode.replaceAll('.', '_'),
              status: 'error',
            })
          }
        }
        throw Error(
          T.error[res.errors[0].errorCode.replaceAll('.', '_')]
            ? __(T.error[res.errors[0].errorCode.replaceAll('.', '_')])
            : res.errors[0].errorCode.replaceAll('.', '_')
        )
      }
      if (
        res.warnings.length > 0 &&
        !(await askUserConfirmation(
          `${
            T.error[res.warnings[0].warningCode.replaceAll('.', '_')]
              ? __(T.error[res.warnings[0].warningCode.replaceAll('.', '_')])
              : res.warnings[0].warningCode.replaceAll('.', '_')
          }`,
          'Proseguire?'
        ))
      ) {
        return this.setState({ isVerifing: false })
      }
      this.setState({
        item: res.item,
        tag,
      })
      if (mode === 'SCARTO') return this.setState({ showScartoModal: true })
      this.setState({ isVerifing: false })
      if (mode === 'COLLAUDO') this.collaudoItem()
      if (mode === 'CANCELLAZIONE') {
        if (!res.item) throw Error('Impossibile eseguire cancellazione, nessun item associato')
        this.cancellaItem()
      }
    } catch (error) {
      this.setState({
        isReading: false,
        isVerifing: false,
      })
      showToast({
        title: __(T.error.error),
        description: (error as Error).message ?? 'Generic error',
        status: 'error',
      })
    }
  }

  collaudoItem = async () => {
    const { tag, serie, counters, lastSearchedSerieCode, item } = this.state
    const additionalSerieData = serie?.data.additional
    try {
      DataDogLogger.addAction('processAssociate', { serie, tag })
      this.setState({ isWriting: true })
      if (counters!.itemStatusCounts.seriesSize - counters!.itemStatusCounts.inspectorTested <= 0)
        throw Error(__(T.error.serie_limit_associate_error))
      if (!AppStore.emulation) {
        const cliente = additionalSerieData?.customerOrder?.trim() || 'N00035'
        //if the string is less than 6 characters it will add 0 at the end of the string
        const clienteFinal = cliente.length < 6 ? cliente.padEnd(6, '0') : cliente
        const res = await QualityControlAgent.writeTagCQ(
          AppStore.loggedUser?.username as string,
          AppStore.defaultPostazione ?? '',
          tag?.uid as string,
          additionalSerieData?.workerSyncCode as string,
          serie?.code as string,
          (serie?.data.values.Stagione as string) ?? '-',
          (additionalSerieData?.productSku as string) ?? '',
          additionalSerieData?.productSize ?? '',
          clienteFinal,
          additionalSerieData?.nationCode ?? ''
        )
        if (res?.status !== '0') throw Error(__(T.error.writing_tag_error))
      }
      await EncodingsQC.forceCollaudo({
        ...(item as any),
        status: 'CQ2',
        causal: undefined,
        upc: serie?.data.additional.productCode,
        productionOrderRow: item?.productionOrderRow,
        product: item?.product,
      })
      this.setState({
        isWriting: false,
        isReading: false,
        lastReadedTag: tag?.uid,
      })
      Sounds.success()
      showToast({
        title: __(T.misc.success),
        description: __(T.messages.product_associated_correctly),
        status: 'success',
      })
      await this.getUpdatedSerie(lastSearchedSerieCode ?? '', this.isPelletteria)
    } catch (error) {
      DataDogLogger.addError(error, { type: 'processAssociate' })
      this.setState({ isWriting: false })
      showToast({
        title: __(T.error.error),
        description: (error as Error).message ?? 'Generic error',
        status: 'error',
      })
    }
  }

  scartoItem = async (reversible = true) => {
    const { tag, serie, selectedCausal, lastSearchedSerieCode } = this.state
    try {
      DataDogLogger.addAction('processDrop', { serie, tag, selectedCausal })
      this.setState({ showScartoModal: false, isVerifing: false, isReading: false, isWriting: true })
      if (!AppStore.emulation) {
        const res = await QualityControlAgent.writeTagSC(
          AppStore.loggedUser?.username as string,
          tag?.uid as string,
          AppStore.defaultPostazione ?? '',
          selectedCausal?.id as string
        )
        if (res?.status !== '0') throw Error(__(T.error.writing_tag_error))
      }
      const response = await EncodingsQC.dropLotInspector({
        productionOrderId: serie!.id,
        productId: serie!.productId,
        identifiers: [{ code: tag?.uid, type: 'NFCTag' }] as any,
        zoneId: AppStore.defaultZone?.id,
        configurationId: this.operation.id,
        causalId: selectedCausal?.id,
        reversible,
        testingLotIds: serie?.data.currentLotData?.map(({ id }) => id),
      })
      if (!response.success) throw Error(__(T.error.product_drop_error))
      Sounds.success()
      showToast({
        title: __(T.misc.success),
        description: __('Prodotto scartato correttamente'),
        status: 'success',
      })
      this.setState({
        errorSerie: undefined,
        isWriting: false,
        isReading: false,
      })
      this.changeOptions('COLLAUDO')
      await this.getUpdatedSerie(lastSearchedSerieCode ?? '', /*this.isPelletteria*/ false)
    } catch (error) {
      DataDogLogger.addError(error, { type: 'processDrop' })
      this.setState({ isWriting: false })
      showToast({
        title: __(T.error.error),
        description: (error as Error).message ?? 'Generic error',
        status: 'error',
      })
    }
  }

  cancellaItem = async () => {
    const { tag, serie, item, lastSearchedSerieCode } = this.state
    try {
      DataDogLogger.addAction('processDelete', { tag, serie, item })
      this.setState({ isWriting: true })
      if (!AppStore.emulation) {
        const res = await QualityControlAgent.cancelTag(
          AppStore.loggedUser?.username as string,
          tag?.uid as string,
          AppStore.defaultPostazione ?? ''
        )
        if (res?.status !== '0') {
          throw Error(__(T.error.tag_reset_error))
        }
      }
      await EncodingsQC.deleteLotInspector({
        productionOrderId: item?.productionOrderRow?.order?.id ?? serie?.id,
        productId: item?.product?.id as string,
        identifiers: [{ code: tag?.uid, type: 'NFCTag' }] as any,
        zoneId: AppStore.defaultZone?.id,
        configurationId: this.operation.id,
      })
      this.setState({
        errorSerie: undefined,
        isWriting: false,
        isReading: false,
      })
      Sounds.success()
      showToast({
        title: __(T.misc.success),
        description: __('Prodotto cancellato correttamente'),
        status: 'success',
      })
      this.changeOptions('COLLAUDO')
      await this.getUpdatedSerie(lastSearchedSerieCode ?? '', /*this.isPelletteria*/ false)
    } catch (error) {
      DataDogLogger.addError(error, { type: 'processDelete' })
      this.setState({ isWriting: false })
      showToast({
        title: __(T.error.error),
        description: (error as Error).message ?? 'Generic error',
        status: 'error',
      })
    }
  }

  stopReader = async () => {
    await QualityControlAgent.stopReader()
    await sleep(700)
    this.setState({
      isReading: false,
    })
  }

  //funzione che chiude il lotto
  closeLotto = async (lots?: CQLot[]) => {
    try {
      const { serie, lastSearchedSerieCode, toBeTested, inspectorTested } = this.state
      const lotData = lots || serie!.data!.currentLotData!
      //DataDogLogger.addAction('closeSerie', { serie: serie })
      this.setState({ isActionInProgress: true }) //settings action in progress for disable buttons click
      //fare check su
      //if (counters.toBeTested - serie.counters.inspectorTested <= 0)
      // throw Error('Associare almeno un item prima di chiudere il lotto')
      if (
        toBeTested! > inspectorTested! &&
        !(await askUserConfirmation(__(T.confirm.discrepancy_title), __(T.confirm.serie_found_discrepancy)))
      )
        return this.setState({ isActionInProgress: false }) //no action in progress, enable buttons
      const res = await EncodingsQC.publishLotti({
        orderId: serie!.code,
        lotIds: lotData.map(({ id }) => id),
      })
      if (res.errors && res.errors.length > 0) {
        throw Error(T.error[res.errors[0].errorCode] ? __(T.error[res.errors[0].errorCode]) : res.errors[0].errorCode)
      }
      this.clearStatus()
      return showToast({
        title: __(T.misc.success),
        description: `Lotto/i chiuso correttamente `,
        status: 'success',
      })
    } catch (error) {
      DataDogLogger.addError(error, { type: 'closeSerie' })
      showToast({
        sound: false,
        title: __(T.error.error),
        description: (error as Error).message,
        status: 'error',
      })
      return this.setState({ isActionInProgress: false })
    }
  }

  //funzione che chiude il lotto
  interruptLotto = async (lotIds: CQLot[] = [], code = '') => {
    try {
      const { counters, serie, lastSearchedSerieCode, toBeTested, inspectorTested } = this.state
      //DataDogLogger.addAction('closeSerie', { serie: serie })
      this.setState({ isActionInProgress: true }) //settings action in progress for disable buttons click
      //TOASK: chiedere info sui check prima della chiusura
      //TOASK: chiedere info su scartati non reversibili che superano il totale - da testare
      //if (counters.toBeTested - serie.counters.inspectorTested <= 0)
      // throw Error('Associare almeno un item prima di chiudere il lotto')
      if (
        lotIds.length === 0 &&
        !(await askUserConfirmation(
          __(T.messages.serie_confirm_interrupt),
          __(T.messages.serie_interrupt_association_question)
        ))
      )
        return this.setState({ isActionInProgress: false }) //no action in progress, enable buttons
      const res = await EncodingsQC.interruptLotti({
        orderId: code !== '' ? code : serie!.id,
        lotIds: lotIds.length > 0 ? lotIds.map(({ id }) => id) : serie!.data!.currentLotData!.map(({ id }) => id),
      })
      if (res.errors && res.errors.length > 0) {
        throw Error(T.error[res.errors[0].errorCode] ? __(T.error[res.errors[0].errorCode]) : res.errors[0].errorCode)
      }
      lotIds.length === 0 && this.clearStatus()
      this.setState({ isActionInProgress: false })
      return showToast({
        title: __(T.misc.success),
        description: `Lotto/i interrotto correttamente `,
        status: 'success',
      })
    } catch (error) {
      showToast({
        sound: false,
        title: __(T.error.error),
        description: (error as Error).message,
        status: 'error',
      })
      return this.setState({ isActionInProgress: false })
    }
  }

  submitTestingSheet = async (testingSheet: Partial<TestingSheet>, isInModify: boolean) => {
    try {
      const { serie, qualitySheetData, testingSheetData } = this.state
      const { notes, attachmentFiles } = testingSheet

      DataDogLogger.addAction('submitTestingSheet', {
        serie: serie ?? 'serie is undefined',
        qualitySheetData,
        testingSheetData,
        notes,
      })

      if (!isInModify) {
        const files = {}
        attachmentFiles.map((file) =>
          Object.assign(files, { [file.name]: { content: file.base64, description: file.name } })
        )
        const newTestingSheet = {
          qualitySheetId: qualitySheetData?.id,
          orderId: serie!.id,
          notes: notes,
          attachmentFiles: files,
        }

        await EncodingsQC.createTestingSheet(newTestingSheet)
        const res = (await EncodingsQC.getTestingSheet(serie!.id))[0]
        this.setState({ testingSheetData: res })
      } else {
        if (!testingSheetData) throw Error('Testing sheet not set')

        const files = {}
        attachmentFiles
          .filter((file) => !!file.preview)
          .map((file) => Object.assign(files, { [file.name]: { content: file.base64, description: file.name } }))
        const newTestingSheet = {
          id: testingSheetData.id,
          inspectionNote: testingSheet.notes as string,
          attachments: testingSheetData.attachments,
          qualitySheet: testingSheetData.qualitySheet,
          code: testingSheetData.code,
        }
        await EncodingsQC.addFilesQualitySheet(newTestingSheet.code, files)
        const res = await EncodingsQC.updateTestingSheet(newTestingSheet)
        this.setState({ testingSheetData: res })
      }
      this.toggleSeriesNotesModal()
    } catch (error) {
      DataDogLogger.addError(error, { type: 'submitTestingSheet' })
      showToast({
        title: __(T.error.error),
        description: (error as Error).message,
        status: 'error',
      })
      this.toggleSeriesNotesModal()
    }
  }

  toggleSeriesNotesModal = () => {
    const { showSeriesNotesModal, serie } = this.state
    DataDogLogger.addAction('toggleSeriesNotesModal', {
      serie: serie ?? 'serie is undefined',
      show: !showSeriesNotesModal,
    })
    this.setState({ showSeriesNotesModal: !this.state.showSeriesNotesModal })
  }

  //Mostra modale per info lotti
  showLotsModal = async () => {
    const { lots, serie, counters } = this.state
    const lotCounts = counters?.itemStatusCounts.lotCounts
    const serieCode = serie?.code
    const modalId = 'cq-lots'
    let options: any[] = []
    for (let i = 0; i < lots!.length; i++) {
      const currentLotCount = { tested: lotCounts?.find((itmInner) => itmInner.lotId === lots![i].id)?.tested }
      options.push({
        ...lots![i],
        ...currentLotCount,
      })
    }
    options = options.sort((a, b) => (a.code > b.code ? 1 : -1))
    return new Promise((resolve) => {
      openModal({
        id: modalId,
        body: (
          <SelectLotsModal
            modalId={modalId}
            title="LOTTI CONFERMATI"
            visible
            options={options ?? []}
            onSelect={(items) => {
              resolve(items)
              closeModal(modalId)
            }}
            lotCode="code"
            date="creationDate"
            serie={serieCode ?? ''}
            onClose={() => {
              closeModal(modalId)
            }}
          />
        ),
      })
    })
  }

  renderHeader = () => {
    const { options, isReading, isVerifing, isWriting, qualitySheetData, serie } = this.state
    const showAntennaReadingIcon = isReading || isWriting || isVerifing
    return (
      <>
        {serie && qualitySheetData && <CustomBtn onClick={this.toggleSeriesNotesModal}>Note Serie</CustomBtn>}
        {qualitySheetData && (
          <Button variant="secondary" style={{ minHeight: 55 }} onClick={this.toggleQualitySheetDetail}>
            Scheda qualità
          </Button>
        )}
        <Spacer />
        <Tab options={options} onOptionSelected={this.changeOptions} disabled={showAntennaReadingIcon} />
      </>
    )
  }

  renderBottomBar = () => {
    const { isReading, isVerifing, isWriting, hideStartReaderButton } = this.state
    const showAntennaReadingIcon = isReading || isWriting || isVerifing
    return (
      <>
        {showAntennaReadingIcon && (
          <Box row>
            <Box flex>
              <AntennaBottomControl />
            </Box>
            <Spacer />
            <Button title="Stop lettore" onClick={this.stopReader} disabled={isWriting || isVerifing} />
          </Box>
        )}
        {!showAntennaReadingIcon && !hideStartReaderButton && (
          <Button title={__(T.placeholder.start_reader)} onClick={this.startReader} />
        )}
      </>
    )
  }

  renderScartoModal = () => {
    return (
      <Box>
        <TextBox text="Seleziona un tipo di scarto" type="error" />
        <Box row mt={15} center>
          <Button
            variant="default"
            title={__(T.misc.cancel)}
            onClick={() => {
              return this.setState({ showScartoModal: false })
            }}
          />
          <Spacer />
          <Button title="Reversibile" onClick={this.scartoItem} />
          <Spacer />
          <Button title="Irreversibile" onClick={() => this.scartoItem(false)} />
        </Box>
      </Box>
    )
  }

  renderInfoSerie = () => {
    const {
      serie,
      testedPieces,
      inspectorTested,
      discarded,
      toBeTested,
      isActionInProgress,
      warningText,
      isReading,
      isWriting,
      isVerifing,
      hasUnexpectedTag,
    } = this.state
    if (!serie) return

    const disableButton = isWriting || isVerifing || hasUnexpectedTag
    return (
      <>
        <InfoCard item={serie!.data.values} title="Serie" subtitle={serie!.code} />
        <Spacer />
        <CounterContainer row={false}>
          <Card title="Info lotti" nomargin style={{ minWidth: 125, padding: 15 }}>
            <Box style={{ flexDirection: 'row', justifyContent: 'right', marginTop: -20, zIndex: 1 }}>
              <Icons.Info
                style={{ alignSelf: 'center' }}
                width={20}
                height={20}
                onClick={() => {
                  this.showLotsModal()
                }}
              />
            </Box>
          </Card>
        </CounterContainer>
        <Spacer />
        <CounterContainer row={false}>
          <Card title="Totale pezzi" nomargin style={{ minWidth: 125, padding: 5, paddingLeft: 10 }}>
            <Counter>{testedPieces}</Counter>
          </Card>
        </CounterContainer>
        <Spacer />
        <CounterContainer row>
          <Card title={__(T.placeholder.tested)} nomargin style={{ minWidth: 125, padding: 5, paddingLeft: 10 }}>
            <Counter>{inspectorTested}</Counter>
          </Card>
          <Card title="Scarti NR" nomargin style={{ minWidth: 125, padding: 5, paddingLeft: 10 }}>
            <Counter>{discarded}</Counter>
          </Card>
          <Card title="Da testare" nomargin style={{ minWidth: 125, padding: 5, paddingLeft: 10 }}>
            <Counter>{toBeTested}</Counter>
          </Card>
        </CounterContainer>
        <Spacer />
        <Box flex style={{ justifyContent: 'flex-end' }}>
          <Button
            title="Conferma lotti"
            disabled={disableButton}
            loading={isActionInProgress}
            variant="primary"
            onClick={this.closeLotto}
          />
        </Box>
        <Spacer />
        <Box flex style={{ justifyContent: 'flex-end' }}>
          <Button
            title="Interrompi lotti"
            disabled={disableButton}
            loading={isActionInProgress}
            variant="error"
            onClick={this.interruptLotto}
          />
        </Box>
        <Spacer />
      </>
    )
  }
  renderSideBar = () => {
    const { errorSerie, isReading, isWriting, isVerifing, warningText, options, isVAS } = this.state
    const isDisabled = isReading || isWriting || isVerifing
    const selectedOption = options.find((opt) => opt.active === true)?.value
    return (
      <>
        <Page.Sidebar style={{ overflowY: 'auto', scrollbarWidth: 'none' }}>
          <Input
            registerClear={(cb) => (this.clearInputRef = cb)}
            inputRef={this.inputRef}
            autoFocus={this.inputRef?.current?.value === '' && selectedOption !== 'SCARTO'}
            onChange={() => this.forceUpdate()}
            barcode
            error={errorSerie}
            placeholder="Barcode serie"
            onEnter={this.searchSerie}
            disabled={isDisabled}
            focusOptions={{ preventScroll: true }}
          />
          <Spacer />
          {warningText && (
            <>
              <WarningText text={warningText} type="warning" p={2} />
              <Spacer />
            </>
          )}
          {isVAS && (
            <>
              <WarningText text="ATTENZIONE SERIE VAS" type="warning" p={2} />
              <Spacer />
            </>
          )}
          {this.renderInfoSerie()}
        </Page.Sidebar>
      </>
    )
  }

  renderIsReadingBox = () => {
    return (
      <Box flex center>
        <Placeholder style={{ width: 370 }}>{__(T.messages.near_product_to_reader)}</Placeholder>
      </Box>
    )
  }

  renderIsVerifingBox = () => {
    return (
      <TagReadBox bgColor="#18a0c2">
        <TagReadPlaceholder color="#fff" style={{ width: 370 }}>
          Verifica in corso... Non muoverlo dal lettore!
        </TagReadPlaceholder>
      </TagReadBox>
    )
  }

  renderIsWritingBox = () => {
    return (
      <TagReadBox bgColor="#18a0c2">
        <TagReadPlaceholder color="#fff" style={{ width: 370 }}>
          Scrittura in corso... Non muoverlo dal lettore!
        </TagReadPlaceholder>
      </TagReadBox>
    )
  }

  renderScartoSelect = () => {
    const { selectedCausal, causal } = this.state
    return (
      <Box flex style={{ width: '100%' }}>
        <Select
          onSelect={(causalSel) => {
            //DataDogLogger.addAction('Causal selection', { serie, causal })
            this.setState({ selectedCausal: causalSel })
          }}
          placeholder={__(T.placeholder.drop_causal)}
          options={causal}
          config={{ value: 'id', label: 'fastId', secondaryLabel: 'description' }}
          isClearable={false}
          defaultValue={selectedCausal}
          multiLabel
        />
      </Box>
    )
  }

  renderQualitySheetModal = () => {
    const { showQualitySheetModal, serie, qualitySheetData } = this.state
    return (
      <>
        {showQualitySheetModal && (
          <QualitySheetModal
            serie={serie}
            qualitySheet={qualitySheetData}
            onClose={this.toggleQualitySheetDetail}
            visible={this.state.showQualitySheetModal}
          />
        )}
      </>
    )
  }

  emulateTag = (tags: string[]) => {
    const tagRead = {
      errorDesc: '',
      status: '0',
      uid: tags[0],
      uuid: '045442D2B16C80045442D2B16C80',
    }
    this.verifyReadedTag(tagRead)
  }

  render() {
    const {
      isReading,
      isVerifing,
      isWriting,
      isSerieSet,
      options,
      showScartoModal,
      showSeriesNotesModal,
      testingSheetData,
    } = this.state
    const mode = options.find((opt) => opt.active === true)?.value
    return (
      <Page
        title="Quality Control"
        enableEmulation
        headerRight={this.renderHeader()}
        emulationFunction={this.emulateTag}
      >
        {this.renderSideBar()}
        <Page.Content>
          {isSerieSet && !showScartoModal && (
            <>
              {mode === 'SCARTO' && this.renderScartoSelect()}
              <Box flex hcenter vcenter>
                {isReading && this.renderIsReadingBox()}
                {isVerifing && this.renderIsVerifingBox()}
                {isWriting && this.renderIsWritingBox()}
              </Box>
              <Spacer />
              {this.renderBottomBar()}
            </>
          )}
          {showScartoModal && this.renderScartoModal()}
        </Page.Content>
        {this.renderQualitySheetModal()}
        {showSeriesNotesModal && (
          <SeriesNotesModal
            onSubmitCallback={(testingSheet, updateSheet) => this.submitTestingSheet(testingSheet, updateSheet)}
            testingSheet={testingSheetData}
            onClose={this.toggleSeriesNotesModal}
            visible={this.state.showSeriesNotesModal}
          />
        )}
      </Page>
    )
  }
}

const TagReadBox = styled.div<{ bgColor: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  ${({ bgColor }) => `background-color: ${bgColor}`};
  margin: 20px;
  border-radius: 20px;
`

const TagReadPlaceholder = styled.div<{ color: string }>`
  font-style: normal;
  font-weight: 700;
  font-size: 38px;
  line-height: 38px;
  text-align: center;
  cursor: default;
  ${({ color }) => `color: ${color}`};
`

const Counter = styled(Box)`
  min-width: 80px;
  font-size: 20px;
  font-weight: bold;
`

const CounterContainer = styled(Box)`
  justify-content: space-between;
`
const WarningText = styled(TextBox)`
  font-size: 15px;
`
const Legend = styled(Box)`
  display: flex;
  flex-shrink: 0;
  justify-content: space-around;
  flex-direction: row;
  width: 100%;
`
const LegendRow = styled(Box)`
  display: flex;
  flex-direction: row;
`
const LegendIcon = styled.div<{ color: string }>`
  height: 20px;
  width: 20px;
  margin-right: 5px;
  ${({ color }) => `background-color: ${color}`};
`
const LegendText = styled(Box)`
  font-size: 15px;
`
const CustomBtn = styled(Button)`
  background: none;
  color: #3265c7;
  border-color: none;
  box-shadow: none;
  text-decoration: underline;
`
